import { useState, useEffect, useRef, useCallback } from 'react';
import { getStorage, ref, getDownloadURL, deleteObject } from "firebase/storage";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "./firebase";

const useAudioPlayer = (albumId, albumOwnerId, user) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [playingTrackId, setPlayingTrackId] = useState(null);
  const [playingSegmentIndex, setPlayingSegmentIndex] = useState(0);
  const [selectedTrackId, setSelectedTrackId] = useState(null);
  const audioRef = useRef(new Audio());
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const currentTrackRef = useRef(null);

  const userIdRef = useRef(user ? user.uid : null);

  useEffect(() => {
    userIdRef.current = user ? user.uid : null;
  }, [user]);

  // Handle audio events
  useEffect(() => {
    const audio = audioRef.current;

    const handleTimeUpdate = () => {
      setCurrentTime(audio.currentTime);
    };

    const handleDurationChange = () => {
      setDuration(audio.duration);
    };

    const handleAudioEnd = () => {
      if (currentTrackRef.current) {
        const nextSegmentIndex = playingSegmentIndex + 1;
        if (nextSegmentIndex < currentTrackRef.current.audioSegments.length) {
          // Play next segment
          playSegment(currentTrackRef.current, nextSegmentIndex);
        } else {
          // End of track
          setIsPlaying(false);
          setPlayingTrackId(null);
          setPlayingSegmentIndex(0);
          currentTrackRef.current = null;
        }
      } else {
        setIsPlaying(false);
      }
    };

    audio.addEventListener('timeupdate', handleTimeUpdate);
    audio.addEventListener('durationchange', handleDurationChange);
    audio.addEventListener('ended', handleAudioEnd);

    return () => {
      audio.removeEventListener('timeupdate', handleTimeUpdate);
      audio.removeEventListener('durationchange', handleDurationChange);
      audio.removeEventListener('ended', handleAudioEnd);
    };
  }, [playingSegmentIndex]);

  const playSegment = useCallback(async (track, segmentIndex) => {
    const segment = track.audioSegments[segmentIndex];
    const audioId = segment.id;

    const storage = getStorage();
    const m4aPath = `audio_segments/${segment.recordedBy}/${albumId}/segment-${audioId}.m4a`;

    try {
      const m4aRef = ref(storage, m4aPath);
      const audioUrl = await getDownloadURL(m4aRef);

      audioRef.current.src = audioUrl;
      await audioRef.current.play();
      setPlayingTrackId(audioId);
      setSelectedTrackId(audioId);
      setPlayingSegmentIndex(segmentIndex);
      setIsPlaying(true);
      currentTrackRef.current = track;
    } catch (error) {
      console.error("Error loading or playing audio file:", error);
    }
  }, [albumId]);

  const handlePlayPause = useCallback(
    async (track, segmentIndex = 0) => {
      if (!track.audioSegments || track.audioSegments.length === 0) {
        console.error("No audio segments found for track:", track);
        return;
      }

      if (isPlaying && track.audioSegments[segmentIndex].id === playingTrackId) {
        audioRef.current.pause();
        setIsPlaying(false);
      } else {
        await playSegment(track, segmentIndex);
      }

      // Set selectedTrackId whenever a track is interacted with
      setSelectedTrackId(track.audioSegments[segmentIndex].id);
    },
    [isPlaying, playingTrackId, playSegment]
  );

  const handleSeek = (time) => {
    audioRef.current.currentTime = time;
  };

  const handleDeleteSegment = useCallback(async (trackId, segmentId) => {
    try {
      // Fetch the album document
      const albumRef = doc(db, "albums", albumId);
      const albumSnapshot = await getDoc(albumRef);

      if (!albumSnapshot.exists()) {
        console.error("Album not found");
        return;
      }

      const albumData = albumSnapshot.data();
      const tracks = albumData.tracks || [];

      // Find the track
      const trackIndex = tracks.findIndex((track) => track.id === trackId);
      if (trackIndex === -1) {
        console.error("Track not found");
        return;
      }

      const track = tracks[trackIndex];

      // Find the segment
      const segmentIndex = track.audioSegments.findIndex((segment) => segment.id === segmentId);
      if (segmentIndex === -1) {
        console.error("Segment not found");
        return;
      }

      const segment = track.audioSegments[segmentIndex];

      // Check if the current user is authorized to delete the segment
      if (!user || user.uid !== segment.recordedBy) {
        console.error("User not authorized to delete this segment");
        return;
      }

      // Remove the segment from the track
      track.audioSegments.splice(segmentIndex, 1);

      // Update the track in the tracks array
      tracks[trackIndex] = track;

      // Update the album document
      await updateDoc(albumRef, {
        tracks: tracks,
        updatedAt: new Date(),
      });

      // Delete the audio file from storage
      const storage = getStorage();
      const m4aPath = `audio_segments/${segment.recordedBy}/${albumId}/segment-${segmentId}.m4a`;
      await deleteObject(ref(storage, m4aPath));

      console.log("Segment deleted successfully");
    } catch (error) {
      console.error("Error deleting segment:", error);
    }
  }, [albumId, user]);

  return {
    isPlaying,
    playingTrackId,
    playingSegmentIndex,
    selectedTrackId,
    currentTime,
    duration,
    handlePlayPause,
    handleSeek,
    audioRef,
    handleDeleteSegment,
  };
};

export default useAudioPlayer;
